import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _1d3a6f12 = () => interopDefault(import('../pages/about-ecomdy-pixel/index.vue' /* webpackChunkName: "pages/about-ecomdy-pixel/index" */))
const _743bd74e = () => interopDefault(import('../pages/case-study/index.vue' /* webpackChunkName: "pages/case-study/index" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _30a2e610 = () => interopDefault(import('../pages/coming-soon/index.vue' /* webpackChunkName: "pages/coming-soon/index" */))
const _890f08ca = () => interopDefault(import('../pages/company-about-us/index.vue' /* webpackChunkName: "pages/company-about-us/index" */))
const _6279402e = () => interopDefault(import('../pages/company-careers/index.vue' /* webpackChunkName: "pages/company-careers/index" */))
const _270b4a0a = () => interopDefault(import('../pages/company-our-team/index.vue' /* webpackChunkName: "pages/company-our-team/index" */))
const _45c2020a = () => interopDefault(import('../pages/creative-video/index.vue' /* webpackChunkName: "pages/creative-video/index" */))
const _0e4ed2b8 = () => interopDefault(import('../pages/ecomdy-airwallex/index.vue' /* webpackChunkName: "pages/ecomdy-airwallex/index" */))
const _5840e678 = () => interopDefault(import('../pages/ecomdy-platform/index.vue' /* webpackChunkName: "pages/ecomdy-platform/index" */))
const _5b917350 = () => interopDefault(import('../pages/Ecomdy-platform/index.vue' /* webpackChunkName: "pages/Ecomdy-platform/index" */))
const _71750c85 = () => interopDefault(import('../pages/home-pixel/index.vue' /* webpackChunkName: "pages/home-pixel/index" */))
const _2d06dd5c = () => interopDefault(import('../pages/maintenance/index.vue' /* webpackChunkName: "pages/maintenance/index" */))
const _1c6abc50 = () => interopDefault(import('../pages/partners/index.vue' /* webpackChunkName: "pages/partners/index" */))
const _e99ef7e6 = () => interopDefault(import('../pages/partnership/index.vue' /* webpackChunkName: "pages/partnership/index" */))
const _16cf35f4 = () => interopDefault(import('../pages/pixel-policy/index.vue' /* webpackChunkName: "pages/pixel-policy/index" */))
const _621a6f24 = () => interopDefault(import('../pages/strategic-partner/index.vue' /* webpackChunkName: "pages/strategic-partner/index" */))
const _d24c3a96 = () => interopDefault(import('../pages/stripe-partner/index.vue' /* webpackChunkName: "pages/stripe-partner/index" */))
const _3bd0b025 = () => interopDefault(import('../pages/terms-policy/index.vue' /* webpackChunkName: "pages/terms-policy/index" */))
const _29d4a29c = () => interopDefault(import('../pages/tiktok-shop/index.vue' /* webpackChunkName: "pages/tiktok-shop/index" */))
const _6c75c34e = () => interopDefault(import('../pages/white-label/index.vue' /* webpackChunkName: "pages/white-label/index" */))
const _16806bc8 = () => interopDefault(import('../pages/case-study/jewel-kingdom.vue' /* webpackChunkName: "pages/case-study/jewel-kingdom" */))
const _49d3b155 = () => interopDefault(import('../pages/case-study/laby.vue' /* webpackChunkName: "pages/case-study/laby" */))
const _d93cc62e = () => interopDefault(import('../pages/company-careers/account-manager.vue' /* webpackChunkName: "pages/company-careers/account-manager" */))
const _d4336044 = () => interopDefault(import('../pages/company-careers/backend-development.vue' /* webpackChunkName: "pages/company-careers/backend-development" */))
const _1462c7fc = () => interopDefault(import('../pages/company-careers/business-development.vue' /* webpackChunkName: "pages/company-careers/business-development" */))
const _1d711082 = () => interopDefault(import('../pages/company-careers/designer.vue' /* webpackChunkName: "pages/company-careers/designer" */))
const _a281ac9c = () => interopDefault(import('../pages/company-careers/financial-accountant.vue' /* webpackChunkName: "pages/company-careers/financial-accountant" */))
const _09238654 = () => interopDefault(import('../pages/company-careers/frontend-development.vue' /* webpackChunkName: "pages/company-careers/frontend-development" */))
const _4049baee = () => interopDefault(import('../pages/company-careers/general-accounting.vue' /* webpackChunkName: "pages/company-careers/general-accounting" */))
const _09cae5dd = () => interopDefault(import('../pages/company-careers/leader.vue' /* webpackChunkName: "pages/company-careers/leader" */))
const _349ed4fc = () => interopDefault(import('../pages/company-careers/marketing.vue' /* webpackChunkName: "pages/company-careers/marketing" */))
const _515c5e60 = () => interopDefault(import('../pages/company-careers/partnership.vue' /* webpackChunkName: "pages/company-careers/partnership" */))
const _e4bc9930 = () => interopDefault(import('../pages/company-careers/sales.vue' /* webpackChunkName: "pages/company-careers/sales" */))
const _29b26fab = () => interopDefault(import('../pages/company-careers/support.vue' /* webpackChunkName: "pages/company-careers/support" */))
const _816adea8 = () => interopDefault(import('../pages/company-careers/web-tester.vue' /* webpackChunkName: "pages/company-careers/web-tester" */))
const _2997dab4 = () => interopDefault(import('../pages/stripe-partner/success.vue' /* webpackChunkName: "pages/stripe-partner/success" */))
const _1baafdcc = () => interopDefault(import('../pages/terms/terms-of-services.vue' /* webpackChunkName: "pages/terms/terms-of-services" */))
const _440b60c2 = () => interopDefault(import('../pages/creative-video/_slug.vue' /* webpackChunkName: "pages/creative-video/_slug" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about-ecomdy-pixel",
    component: _1d3a6f12,
    name: "about-ecomdy-pixel___en"
  }, {
    path: "/case-study",
    component: _743bd74e,
    name: "case-study___en"
  }, {
    path: "/ch",
    component: _2dfb1658,
    name: "index___ch"
  }, {
    path: "/coming-soon",
    component: _30a2e610,
    name: "coming-soon___en"
  }, {
    path: "/company-about-us",
    component: _890f08ca,
    name: "company-about-us___en"
  }, {
    path: "/company-careers",
    component: _6279402e,
    name: "company-careers___en"
  }, {
    path: "/company-our-team",
    component: _270b4a0a,
    name: "company-our-team___en"
  }, {
    path: "/creative-video",
    component: _45c2020a,
    name: "creative-video___en"
  }, {
    path: "/ecomdy-airwallex",
    component: _0e4ed2b8,
    name: "ecomdy-airwallex___en"
  }, {
    path: "/ecomdy-platform",
    component: _5840e678,
    name: "ecomdy-platform___en"
  }, {
    path: "/Ecomdy-platform",
    component: _5b917350,
    name: "Ecomdy-platform___en"
  }, {
    path: "/home-pixel",
    component: _71750c85,
    name: "home-pixel___en"
  }, {
    path: "/maintenance",
    component: _2d06dd5c,
    name: "maintenance___en"
  }, {
    path: "/partners",
    component: _1c6abc50,
    name: "partners___en"
  }, {
    path: "/partnership",
    component: _e99ef7e6,
    name: "partnership___en"
  }, {
    path: "/pixel-policy",
    component: _16cf35f4,
    name: "pixel-policy___en"
  }, {
    path: "/strategic-partner",
    component: _621a6f24,
    name: "strategic-partner___en"
  }, {
    path: "/stripe-partner",
    component: _d24c3a96,
    name: "stripe-partner___en"
  }, {
    path: "/terms-policy",
    component: _3bd0b025,
    name: "terms-policy___en"
  }, {
    path: "/tiktok-shop",
    component: _29d4a29c,
    name: "tiktok-shop___en"
  }, {
    path: "/white-label",
    component: _6c75c34e,
    name: "white-label___en"
  }, {
    path: "/case-study/jewel-kingdom",
    component: _16806bc8,
    name: "case-study-jewel-kingdom___en"
  }, {
    path: "/case-study/laby",
    component: _49d3b155,
    name: "case-study-laby___en"
  }, {
    path: "/ch/about-ecomdy-pixel",
    component: _1d3a6f12,
    name: "about-ecomdy-pixel___ch"
  }, {
    path: "/ch/case-study",
    component: _743bd74e,
    name: "case-study___ch"
  }, {
    path: "/ch/coming-soon",
    component: _30a2e610,
    name: "coming-soon___ch"
  }, {
    path: "/ch/company-about-us",
    component: _890f08ca,
    name: "company-about-us___ch"
  }, {
    path: "/ch/company-careers",
    component: _6279402e,
    name: "company-careers___ch"
  }, {
    path: "/ch/company-our-team",
    component: _270b4a0a,
    name: "company-our-team___ch"
  }, {
    path: "/ch/creative-video",
    component: _45c2020a,
    name: "creative-video___ch"
  }, {
    path: "/ch/ecomdy-airwallex",
    component: _0e4ed2b8,
    name: "ecomdy-airwallex___ch"
  }, {
    path: "/ch/ecomdy-platform",
    component: _5840e678,
    name: "ecomdy-platform___ch"
  }, {
    path: "/ch/Ecomdy-platform",
    component: _5b917350,
    name: "Ecomdy-platform___ch"
  }, {
    path: "/ch/home-pixel",
    component: _71750c85,
    name: "home-pixel___ch"
  }, {
    path: "/ch/maintenance",
    component: _2d06dd5c,
    name: "maintenance___ch"
  }, {
    path: "/ch/partners",
    component: _1c6abc50,
    name: "partners___ch"
  }, {
    path: "/ch/partnership",
    component: _e99ef7e6,
    name: "partnership___ch"
  }, {
    path: "/ch/pixel-policy",
    component: _16cf35f4,
    name: "pixel-policy___ch"
  }, {
    path: "/ch/strategic-partner",
    component: _621a6f24,
    name: "strategic-partner___ch"
  }, {
    path: "/ch/stripe-partner",
    component: _d24c3a96,
    name: "stripe-partner___ch"
  }, {
    path: "/ch/terms-policy",
    component: _3bd0b025,
    name: "terms-policy___ch"
  }, {
    path: "/ch/tiktok-shop",
    component: _29d4a29c,
    name: "tiktok-shop___ch"
  }, {
    path: "/ch/white-label",
    component: _6c75c34e,
    name: "white-label___ch"
  }, {
    path: "/company-careers/account-manager",
    component: _d93cc62e,
    name: "company-careers-account-manager___en"
  }, {
    path: "/company-careers/backend-development",
    component: _d4336044,
    name: "company-careers-backend-development___en"
  }, {
    path: "/company-careers/business-development",
    component: _1462c7fc,
    name: "company-careers-business-development___en"
  }, {
    path: "/company-careers/designer",
    component: _1d711082,
    name: "company-careers-designer___en"
  }, {
    path: "/company-careers/financial-accountant",
    component: _a281ac9c,
    name: "company-careers-financial-accountant___en"
  }, {
    path: "/company-careers/frontend-development",
    component: _09238654,
    name: "company-careers-frontend-development___en"
  }, {
    path: "/company-careers/general-accounting",
    component: _4049baee,
    name: "company-careers-general-accounting___en"
  }, {
    path: "/company-careers/leader",
    component: _09cae5dd,
    name: "company-careers-leader___en"
  }, {
    path: "/company-careers/marketing",
    component: _349ed4fc,
    name: "company-careers-marketing___en"
  }, {
    path: "/company-careers/partnership",
    component: _515c5e60,
    name: "company-careers-partnership___en"
  }, {
    path: "/company-careers/sales",
    component: _e4bc9930,
    name: "company-careers-sales___en"
  }, {
    path: "/company-careers/support",
    component: _29b26fab,
    name: "company-careers-support___en"
  }, {
    path: "/company-careers/web-tester",
    component: _816adea8,
    name: "company-careers-web-tester___en"
  }, {
    path: "/stripe-partner/success",
    component: _2997dab4,
    name: "stripe-partner-success___en"
  }, {
    path: "/terms/terms-of-services",
    component: _1baafdcc,
    name: "terms-terms-of-services___en"
  }, {
    path: "/ch/case-study/jewel-kingdom",
    component: _16806bc8,
    name: "case-study-jewel-kingdom___ch"
  }, {
    path: "/ch/case-study/laby",
    component: _49d3b155,
    name: "case-study-laby___ch"
  }, {
    path: "/ch/company-careers/account-manager",
    component: _d93cc62e,
    name: "company-careers-account-manager___ch"
  }, {
    path: "/ch/company-careers/backend-development",
    component: _d4336044,
    name: "company-careers-backend-development___ch"
  }, {
    path: "/ch/company-careers/business-development",
    component: _1462c7fc,
    name: "company-careers-business-development___ch"
  }, {
    path: "/ch/company-careers/designer",
    component: _1d711082,
    name: "company-careers-designer___ch"
  }, {
    path: "/ch/company-careers/financial-accountant",
    component: _a281ac9c,
    name: "company-careers-financial-accountant___ch"
  }, {
    path: "/ch/company-careers/frontend-development",
    component: _09238654,
    name: "company-careers-frontend-development___ch"
  }, {
    path: "/ch/company-careers/general-accounting",
    component: _4049baee,
    name: "company-careers-general-accounting___ch"
  }, {
    path: "/ch/company-careers/leader",
    component: _09cae5dd,
    name: "company-careers-leader___ch"
  }, {
    path: "/ch/company-careers/marketing",
    component: _349ed4fc,
    name: "company-careers-marketing___ch"
  }, {
    path: "/ch/company-careers/partnership",
    component: _515c5e60,
    name: "company-careers-partnership___ch"
  }, {
    path: "/ch/company-careers/sales",
    component: _e4bc9930,
    name: "company-careers-sales___ch"
  }, {
    path: "/ch/company-careers/support",
    component: _29b26fab,
    name: "company-careers-support___ch"
  }, {
    path: "/ch/company-careers/web-tester",
    component: _816adea8,
    name: "company-careers-web-tester___ch"
  }, {
    path: "/ch/stripe-partner/success",
    component: _2997dab4,
    name: "stripe-partner-success___ch"
  }, {
    path: "/ch/terms/terms-of-services",
    component: _1baafdcc,
    name: "terms-terms-of-services___ch"
  }, {
    path: "/",
    component: _2dfb1658,
    name: "index___en"
  }, {
    path: "/ch/creative-video/:slug?",
    component: _440b60c2,
    name: "creative-video-slug___ch"
  }, {
    path: "/creative-video/:slug?",
    component: _440b60c2,
    name: "creative-video-slug___en"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
